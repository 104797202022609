







































































































import { Account } from '@/models/account';
import { ErrorManager } from '@/models/error';
import { Shipment } from '@/models/shipment';
import { cloneDeep } from 'lodash';
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';

export default Vue.extend({
  data() {
    return {
      loading: false,
      dialog: false,
      disabled: false,
      canChange: false,

      errorMessage: '',
      localPackage: new Shipment()
    };
  },

  computed: {
    ...mapState('account', ['account']),
    errorOccurred(): boolean {
      return this.errorMessage.length > 0;
    }
  },

  methods: {
    ...mapActions({
      updateShipment: 'shipment/updateShipment'
    }),

    open(shipment: Shipment) {
      this.dialog = true;
      this.localPackage = cloneDeep(shipment);

      this.disabled =
        this.localPackage.user == null ||
        this.account.pk == (this.localPackage.user as Account).pk;
      this.canChange = this.localPackage.user == null;
    },

    close() {
      this.dialog = false;
    },

    updatePackageStaus() {
      delete this.localPackage['invoices'];

      this.localPackage.user = (this.localPackage.user as Account)!.pk;

      this.loading = true;

      this.updateShipment({ id: this.localPackage.id, data: this.localPackage })
        .then(() => this.close())
        .catch((error: any) => {
          this.errorMessage = ErrorManager.extractApiError(error);
        })
        .finally(() => (this.loading = false));
    }
  }
});
