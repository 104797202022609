












































































































































































































































































































































































































































































import { ErrorManager } from '@/models/error';
import { IFetchShipmentParams, Shipment } from '@/models/shipment';
import { mapActions, mapGetters, mapState } from 'vuex';
import PackageForm from './PackageForm.vue';
import PackageStatusDialog from './PackageStatusDialog.vue';
import dateFormat from '@/utils/dateFormat';
import PackageInformationDialog from './PackageInformationDialog.vue';
import { Account } from '@/models/account';
import DatePicker from '../date/DatePicker.vue';
import moment from 'moment';
import { cloneDeep } from 'lodash';
import { axios } from '@/plugins/axios';
import StaffPackageManagerMobile from './StaffPackageManagerMobile.vue';
import auth from '@/mixins/auth';
import FranchiseFilter from '../stafffranchisemanager/FranchiseFilter.vue';
import mixins from '@/utils/mixins';
import Functions from '@/mixins/functions';
import { Franchise } from '@/models/franchise';

export default mixins(auth, Functions).extend({
  components: {
    PackageStatusDialog,
    PackageForm,
    PackageInformationDialog,
    DatePicker,
    StaffPackageManagerMobile,
    FranchiseFilter
  },
  name: 'StaffPackageManager',
  data() {
    return {
      loading: false,
      btnLoad: false,
      dialog: false,
      deleteShipmentDialog: false,
      dueDatetFormDialog: false,

      datePickerValue: moment(new Date()).format('YYYY-MM-DD') as string | Date,

      errorMessage: '',

      params: { search: '', page: 1 } as IFetchShipmentParams,

      chosenShipment: new Shipment(),
      userActiveView: { value: null, text: 'All' } as number | any,
      adminId: 1,

      paymentStatusActiveView: { value: null, text: 'All' } as number | any,
      paymentStatusViews: [
        { value: null, text: 'All' },
        { value: 'true', text: 'Paid' },
        { value: 'false', text: 'Unpaid' }
      ],

      packageAssignmentActiveView: { value: null, text: 'All' } as number | any,
      packageAssignmentViews: [
        { value: null, text: 'All' },
        { value: 'true', text: 'Assigned' },
        { value: 'false', text: 'Unassigned' }
      ],

      franchiseActiveView: { value: null, text: 'All' } as number | any,

      pageCount: 0,
      headers: [
        {
          text: '',
          sortable: false,
          align: 'start',
          width: '20px',
          value: 'data-table-space'
        },
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Created At',
          value: 'created_at'
        },
        {
          text: 'Tracking Number',
          value: 'tracking_number'
        },
        {
          text: 'Package Weight',
          value: 'weight'
        },
        {
          text: 'Pickup Price',
          value: 'package_pickup_cost'
        },
        {
          text: 'Status',
          value: 'status'
        },
        {
          text: 'Invoice',
          value: 'invoices'
        },
        {
          text: '',
          value: 'actions'
        }
      ]
    };
  },
  computed: {
    ...mapState('shipment', ['shipment']),
    ...mapState('user', ['user']),

    ...mapGetters({
      numShipments: 'shipment/getShipmentCount',
      outstandingBalance: 'statistics/getOutstandingBalance'
    }),

    userID(): number {
      const userInfo = JSON.parse(localStorage.getItem('user')!);
      const userId = userInfo == null ? null : userInfo.pk;
      return userId;
    },

    franchise(): Franchise {
      return this.getUserFranchise;
    },

    screenWidth() {
      return window.innerWidth > 600;
    },

    errorOccurred(): boolean {
      return this.errorMessage.length > 0;
    },

    packageHeaders(): any {
      if (this.isSuperAdmin) {
        if (!this.headers.find((header) => header.value == 'franchise')) {
          this.headers.splice(7, 0, {
            text: 'Franchise',
            value: 'franchise'
          });
          this.headers.splice(8, 0, {
            text: 'Warehouse',
            value: 'warehouse'
          });
        }
      }
      return this.headers;
    },

    userViews(): any {
      const allViewsOption = [{ text: 'All', value: null }];

      this.user.map((account: any) => {
        const option = {
          text: `${account.first_name} ${account.last_name}`,
          value: account.pk
        };
        allViewsOption.push(option);
      });

      return allViewsOption;
    }
  },

  watch: {
    'params.search'(search: string) {
      this.debounceFetchUsers({
        ...this.params,
        search: search,
        page: 1
      }).then(() => {
        this.params.page = 1;
      });
    },

    'params.page'(page: number) {
      this.onUpdatePage(page);
    },

    franchiseActiveView: {
      handler(franchise) {
        this.rebuildShipmentList({
          ...this.params,
          franchise: franchise.value
        }).then(() => {
          this.params.franchise = franchise.value;
        });
      }
    },

    userActiveView: {
      handler(user) {
        this.rebuildShipmentList({
          ...this.params,
          user: user.value
        }).then(() => {
          this.params.user = user.value;
        });
      }
    },

    packageAssignmentActiveView: {
      handler(assigned) {
        this.rebuildShipmentList({
          ...this.params,
          assigned: assigned.value
        }).then(() => {
          this.params.assigned = assigned.value;
        });
      }
    },

    paymentStatusActiveView: {
      handler(paid) {
        this.rebuildShipmentList({
          ...this.params,
          paid: paid.value
        }).then(() => {
          this.params.paid = paid.value;
        });
      }
    }
  },

  methods: {
    ...mapActions({
      fetchShipments: 'shipment/fetchShipments',
      deleteShipment: 'shipment/deleteShipment',
      fetchUsers: 'user/fetchUsers',
      fetchAddress: 'address/fetchAddress',
      fetchUserAddresses: 'userAddress/fetchUserAddresses',
      generatePDFInvoice: 'shipment/generatePDFInvoice',
      fetchStatisticsOutstandingBalance:
        'statistics/fetchStatisticsOutstandingBalance',
      fetchAccount: 'account/fetchAccount'
    }),

    dateFormat,

    clearAllErrors() {
      this.errorMessage = '';
    },

    openDialog(item: Shipment, editForm = false) {
      if (editForm) {
        (this as any).$refs.packageform.open(item, editForm);
      } else {
        (this as any).$refs.packageform.open(this.chosenShipment);
      }
    },

    openPackageInformation(item: Shipment) {
      (this as any).$refs.packageinformationdialog.open(item);
    },

    openDueDateFormDialog(shipment: Shipment) {
      this.dueDatetFormDialog = true;
      this.chosenShipment = cloneDeep(shipment);
    },

    selectedShipment(item: Shipment) {
      this.chosenShipment = item;
      this.deleteShipmentDialog = true;
    },

    updatePackageStatus(shipment: Shipment) {
      this.dialog = true;
      (this as any).$refs.packagestatusdialog.open(shipment);
    },

    removeShipment() {
      this.loading = true;
      this.deleteShipment(this.chosenShipment)
        .catch((error: any) => {
          this.errorMessage = ErrorManager.extractApiError(error);
        })
        .finally(() => {
          this.loading = false;
          this.deleteShipmentDialog = false;
        });
    },

    async debounceFetchUsers(params: IFetchShipmentParams) {
      this.clearAllErrors();
      this.loading = true;

      return this.debounce(this.rebuildShipmentList, 200)(params);
    },

    onUpdatePage(newPage: number) {
      this.rebuildShipmentList({
        ...this.params,
        page: newPage
      }).then(() => {
        this.params.page = newPage;
      });
    },

    generateInvoice() {
      this.btnLoad = true;
      const dateChange = moment(this.datePickerValue).format('YYYY-MM-DD');
      this.generatePDFInvoice({
        package: this.chosenShipment.id,
        due_date: dateChange,
        user: (this.chosenShipment.user! as Account).pk
      })
        .then((response) => {
          this.downloadPDFInvoice(response.invoice_pdf);
        })
        .catch((error: any) => {
          this.errorMessage = ErrorManager.extractApiError(error);
        })
        .finally(() => {
          this.dueDatetFormDialog = false;
          this.btnLoad = false;
        });
    },

    downloadPDFInvoice(url: string) {
      const fileTypeIndex = url.lastIndexOf('.');
      const fileType = url.slice(fileTypeIndex, url.length);

      axios.get(url).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute(
          'download',
          `${this.chosenShipment.description} invoice.${fileType}`
        );
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },

    refreshShipmentList() {
      this.rebuildShipmentList(this.params);
    },

    rebuildShipmentList(params: IFetchShipmentParams) {
      /* this will update the URL query based on param values. */
      this.$router
        .replace({
          name: 'package-manager',
          query: params as Record<string, any>
        })
        .catch((e: Error) => e); //catch navigation duplication error

      this.loading = true;
      return this.fetchShipments(params)
        .then(() => {
          this.fetchStatisticsOutstandingBalance(params);
        })
        .catch((error: any) => {
          this.errorMessage = ErrorManager.extractApiError(error);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },

  async mounted() {
    this.loading = true;

    try {
      if (this.isSuperAdmin) {
        this.params.franchise = this.franchiseActiveView.value;
      } else {
        if (!this.franchise) {
          await this.fetchAccount(this.userID);
        }
        this.params.franchise = this.franchise.pk!.toString();
      }

      await this.fetchStatisticsOutstandingBalance(this.params);
      await this.fetchShipments();
      await this.fetchUsers();
    } catch (error) {
      console.error(error);
      this.errorMessage = ErrorManager.extractApiError(error);
    } finally {
      this.loading = false;
    }
  }
});
