





















































































































































































































































































































































































































































































































































































































































import { Account } from '@/models/account';
import { Address } from '@/models/address';
import { UserAddress } from '@/models/user_address';
import { Shipment } from '@/models/shipment';
import { cloneDeep } from 'lodash';
import Vue from 'vue';
import { mapState } from 'vuex';
import dateFormat from '@/utils/dateFormat';
import { axios } from '@/plugins/axios';
import pdf from 'vue-pdf';
import ImageDialog from '../common/ImageDialog.vue';

export default Vue.extend({
  components: {
    pdf,
    ImageDialog
  },
  data() {
    return {
      dialog: false,
      hideTrackingNumber: false,
      shipment: new Shipment(),
      adminId: 1
    };
  },
  computed: {
    ...mapState('address', ['address']),
    ...mapState('userAddress', ['userAddress']),
    ...mapState('user', ['user'])
  },
  methods: {
    dateFormat,

    open(shipment: Shipment, hideTrackingNumber?: boolean) {
      this.shipment = cloneDeep(shipment);

      if (
        this.shipment.user &&
        (this.shipment.user as Account)!.address! == null
      ) {
        (this.shipment.user as Account)!.address! = new Address();
      }
      if (
        this.shipment.user &&
        (this.shipment.user as Account)!.user_delivery_address! == null
      ) {
        (this.shipment
          .user as Account)!.user_delivery_address! = new UserAddress();
      }

      if (hideTrackingNumber) {
        this.hideTrackingNumber = hideTrackingNumber;
      }
      this.dialog = true;
    },

    getUserTypeText(userType: string) {
      if (userType == 'C') {
        return 'Customer';
      } else if (userType == 'A') {
        return 'Admin';
      } else {
        return 'Employee';
      }
    },

    getUserTypeColor(userType: string) {
      if (userType == 'C') {
        return 'primary';
      } else if (userType == 'A') {
        return 'red';
      } else {
        return 'success';
      }
    },

    getPickupParishText(parishType: string) {
      if (parishType == 'MBJ') {
        return 'Montego Bay';
      } else {
        return 'Kingston';
      }
    },

    viewImage(image: string) {
      (this.$refs as any).imageDialog.open(image);
    },

    downloadImage(url: string) {
      const fileTypeIndex = url.lastIndexOf('.');
      const fileType = url.slice(fileTypeIndex, url.length);

      axios({
        url: url,
        method: 'GET',
        responseType: 'blob'
      }).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute(
          'download',
          `${this.shipment.description} image.${fileType}`
        );
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    }
  }
});
