






import Vue from 'vue'
import StaffPackageManager from '../../components/staffpackagemanager/StaffPackageManager.vue'
export default Vue.extend({
    name: 'StaffPackageManagerPage',
    components: {
        StaffPackageManager
    }
})
